import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { PARTNERS_IMAGE_PATH } from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { t, Trans } from '@lingui/macro'

type Props = {
  partnershipSectionHeading: string | LinguiJSMessageDescriptor
}
const PartnerCompanies = ({ partnershipSectionHeading }: Props) => (
  <div className="h-64 p-6 text-center bg-white rounded-lg shadow-lg">
    <h3 className="mb-6 text-xl font-700 text-grey-800">
      <MsgTrans msg={partnershipSectionHeading} />
    </h3>
    <div className="grid grid-cols-2 gap-y-5">
      <div className="flex flex-col items-center space-y-1 text-grey">
        <FastlyImage
          width={45}
          height={45}
          src={`${PARTNERS_IMAGE_PATH}/iit-bombay.png`}
          alt={t`IIT Bombay`}
        />
        <span className="text-sm">
          <Trans>IIT Bombay</Trans>
        </span>
      </div>
      <div className="flex flex-col items-center space-y-1 text-grey">
        <FastlyImage
          width={45}
          height={45}
          src={`${PARTNERS_IMAGE_PATH}/iit-guwahati.png`}
          alt={t`IIT Guwahati`}
        />
        <span className="text-sm">
          <Trans>IIT Guwahati</Trans>
        </span>
      </div>
      <div className="flex flex-col items-center text-grey">
        <FastlyImage
          width={90}
          height={40}
          src={`${PARTNERS_IMAGE_PATH}/hewlett-packard.png`}
          alt={t`Hewlett Packard`}
        />
      </div>
      <div className="flex flex-col items-center space-y-1 text-grey">
        <FastlyImage
          width={45}
          height={45}
          src={`${PARTNERS_IMAGE_PATH}/bits-pilani.png`}
          alt={t`Bits Pilani`}
        />
        <span className="text-sm">
          <Trans>BITS Pilani</Trans>
        </span>
      </div>
    </div>
  </div>
)

export default PartnerCompanies
